import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useAppointmentListAddNew(props, clearForm, emit) {
  // ------------------------------------------------
  // userLocal
  // ------------------------------------------------
  const userLocal = ref(JSON.parse(JSON.stringify(props.userData.value)))
  const resetUserLocal = () => {
    userLocal.value = JSON.parse(JSON.stringify(props.userData.value))
  }
  watch(props.userData, () => {
    resetUserLocal()
  })

  // ------------------------------------------------
  // isAddNewUserSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isAddNewUserSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
//   const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const onSubmit = () => {
    const userData = JSON.parse(JSON.stringify(userLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.userData.value.id) emit('update-appointment', userData.value)
    else emit('add-appointment', userData.value)

    // Close sidebar
    emit('update:is-add-new-user-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------


//   const groupsOptions = computed(() => store.state.calendar.groups)

  /* eslint-enable global-require */

  return {
    userLocal,
    resetUserLocal,
    // calendarOptions,

    // UI
    // fetchAppointments,
    // groupsOptions,
    onSubmit,
  }
}
